var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mew-input", {
        attrs: {
          placeholder: "Pairing Password",
          type: "password",
          "error-messages": _vm.passwordErrorMsg,
        },
        model: {
          value: _vm.pairingPassword,
          callback: function ($$v) {
            _vm.pairingPassword = $$v
          },
          expression: "pairingPassword",
        },
      }),
      _c(
        "v-row",
        { staticClass: "border-container mb-8", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center justify-center pt-5",
              attrs: {
                order: "1",
                cols: "12",
                lg: "6",
                "order-lg": "2",
                "order-md": "1",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/hardware-wallets/coolwallet-sample.png"),
                },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "pa-5",
              attrs: {
                "order-lg": "1",
                "order-md": "2",
                order: "2",
                lg: "6",
                cols: "12",
              },
            },
            [
              _c("h2", { staticClass: "mew-heading-2 text-center ma-0" }, [
                _vm._v(" Where is my pairing password? "),
              ]),
              _c(
                "ol",
                {
                  staticClass: "list-style-on pt-4 pl-3",
                  attrs: { type: "1" },
                },
                [
                  _c("li", [_vm._v("Connect your Cool Wallet device.")]),
                  _c("li", { staticClass: "pt-3" }, [
                    _vm._v("Open CoolBitX mobile app"),
                  ]),
                  _c("li", { staticClass: "pt-3" }, [
                    _vm._v(" Go to "),
                    _c(
                      "b",
                      [
                        _vm._v("Settings "),
                        _c("v-icon", { attrs: { size: "small" } }, [
                          _vm._v(" mdi-arrow-right "),
                        ]),
                        _vm._v(" Show pairing password."),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("p", { staticClass: "pt-3 ml-3" }, [
                _vm._v(" Need more help? "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://kb.myetherwallet.com/en/hardware-wallets/using-coolwallet-with-mew/",
                      rel: "noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v("Read More "),
                    _c(
                      "v-icon",
                      { attrs: { size: "small", color: "greenPrimary" } },
                      [_vm._v(" mdi-launch ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("p", [
                _vm._v(
                  " Note: If device is not available in the pairing selection pop-up, please enable "
                ),
                _c("b", [_vm._v("Allow New Pairing")]),
                _vm._v(" in the mobile app settings "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("mew-button", {
        attrs: {
          "has-full-width": true,
          "btn-size": "xlarge",
          title: "Unlock Wallet",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.coolWalletUnlock.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }